<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      :exportShow="false"
      @getChange="changeSelect"
    >
    </EForm>
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="true"
      :count="count"
      @changePage="changePage"
      @changeSize="changeSize"
      :page="searchForm.current"
      :page_size="searchForm.size"
    >
      <el-table-column fixed="right" label="操作" width="80px">
        <template slot-scope="scope">
          <div>
            <!-- <EButton type="text" v-if="scope.row.confirmStatus == 2 && !scope.row.chargePersonName" @click="confirm(scope.row, 1)">
                          取消
                        </EButton>
                        <EButton type="text" v-if="scope.row.confirmStatus != 2" @click="confirm(scope.row, 2)"> 确认 </EButton>
                        <EButton type="text" @click="confirm(scope.row, 4)"> 退回 </EButton> -->
            <EButton type="text" @click="dialogShow(scope.row)"> 明细</EButton>
          </div>
        </template>
      </el-table-column>
    </ETable>
    <el-dialog title="明细" :visible.sync="dialogVisible" :before-close="() => (dialogVisible = false)">
      <div class="total">合计: {{ totalCount }} 元</div>
      <ETable :tableTitle="tableTitle2" :tableData="tableData2" :needPagination="false"></ETable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm(4)" type="danger" size="small" v-if="status">退 回</el-button>
        <el-button type="primary" @click="confirm(2)" size="small" v-if="status">确 认</el-button>
        <el-button @click="confirm(1)" size="small" v-if="!status">取消确认</el-button>
        <el-button @click="dialogVisible = false" size="small">取消</el-button>

        <!--      <el-button @click="sendback" type="danger" :disabled="disabled" v-if="dialog=='sure'">退 回</el-button>-->
        <!--      <el-button type="primary" @click="sure" :disabled="disabled" v-if="dialog == 'sure' && status == true">确 认</el-button>-->
        <!--      <el-button @click="cancel" v-if="status == false">取 消</el-button>-->
        <!--      <el-button type="primary" @click="handleClick" :disabled="disabled" v-if="dialog != 'sure'">确 定</el-button>-->
      </span>
    </el-dialog>
    <!--        <EDialog-->
    <!--                :dialogVisible="dialogVisible"-->
    <!--                title="明细"-->
    <!--                @handleClose="dialogVisible = false"-->
    <!--                width="50%"-->
    <!--                dialog="sure"-->
    <!--                :status="status"-->
    <!--                :totalCount="totalCount"-->
    <!--                @handleClick="dialogVisible = false"-->
    <!--                @sure="confirm(2)"-->
    <!--                @cancel="confirm(1)"-->
    <!--                @sendback="confirm(4)"-->
    <!--        >-->
    <!--            <ETable :tableTitle="tableTitle2" :tableData="tableData2" :needPagination="false"></ETable>-->
    <!--        </EDialog>-->
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';

  export default {
    name: 'shopInfo',
    data() {
      return {
        tableTitle: [
          // {
          //   label: '交费单号',
          //   prop: 'orderNum',
          // },
          {
            label: '区域',
            prop: 'areaName',
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode',
          },
          {
            label: '商户名称',
            prop: 'tenantName',
          },
          {
            label: '总金额',
            prop: 'totalAmount',
          },
          {
            label: '费用笔数',
            prop: 'feeNum',
          },
          {
            label: '录入人',
            prop: 'createUserCn',
          },
          {
            label: '录入时间',
            prop: 'createTime',
          },
          {
            label: '合同状态',
            prop: 'statusCn',
          },
          {
            label: '联系电话',
            prop: 'phone',
          },
          {
            label: '统一社会信用代码',
            prop: 'socialCreditCode',
          },
          {
            label: '营业执照地址',
            prop: 'businessLicenseAdress',
          },
          {
            label: '开户行及其账号',
            prop: 'bankAccount',
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCode',
            show: true,
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: [],
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: [],
          },
          {
            title: '录入人',
            type: 'text',
            property: 'createUser',
            show: true,
          },
          {
            title: '录入时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true,
          },
          {
            title: '是否确认',
            type: 'select',
            property: 'confirmStatusS',
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '否',
                value: 1,
              },
              {
                label: '是',
                value: 2,
              },
            ],
            show: false,
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          createUser: null,
          areaId: null,
          createTimeStart: null,
          placeId: null,
          confirmStatusS: 1,
          current: 1,
          size: 10,
        },
        count: null,
        dialogVisible: false,
        orderId: '',
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '收费金额',
            prop: 'payAmount',
          },
          {
            label: '期数',
            prop: 'periods',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
          },
        ],
        tableData2: [],
        status: '',
        totalCount: '0',
      };
    },
    watch: {},
    created() {
      this.getData();
      this.getAreaLabel();
    },
    components: { ETable, EDialog, EButton, EForm },
    beforeMount() {},
    methods: {
      confirm(type) {
        // console.log(123, orderId, type);
        //修改状态为2 已确认
        let title = '';
        if (type == 1) {
          title = '取消';
        }
        if (type == 2) {
          title = '确认';
        }
        if (type == 4) {
          title = '退回';
        }

        //修改状态为4 已退回
        this.$messageBox
          .confirm('确定' + title + '该费用吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then((res) => {
            this.cmorderUpdate({
              confirmStatus: type,
              orderId: this.orderId,
            });
          })
          .catch((res) => {});
      },
      async cmorderUpdate(data) {
        let res = await Http.cmorderUpdate(data);
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getData();
          this.dialogVisible = false;
        }
      },
      async dialogShow(scope) {
        let orderId = scope.orderId;
        this.totalCount = scope.totalAmount;
        if (scope.confirmStatus != 2) {
          this.status = true;
        }
        // scope.row.confirmStatus == 2 && !scope.row.chargePersonName
        if (scope.confirmStatus == 2 && !scope.chargePersonName) {
          this.status = false;
        }
        console.log('状态', this.status);
        this.dialogVisible = true;
        this.orderId = orderId;
        let res = await Http.cmorderDetail({ id: orderId });
        if (res.code == 200) {
          this.tableData2 = res.data.items;
          console.log('详情', this.tableData2);
        }
      },
      changeSelect(prop) {
        if (prop == 'areaId') {
          this.getPlaceLabel();
        }
      },
      async changeStatus(id, status) {
        let res = await Http.cmboothinformationUpdStatus({ id, status });
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getData();
        }
      },
      change(id, status) {
        let msg = '';
        if (status == 1) {
          msg = '禁用';
        } else {
          msg = '启用';
        }
        this.$messageBox
          .confirm('确定' + msg + '？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then((res) => {
            this.changeStatus(id, status == 1 ? 0 : 1);
          })
          .catch((res) => {});
      },
      saveData(boothInformationId) {
        this.$refs['form'].validate((valid, object) => {
          console.log(object);
          if (valid) {
            this.boothInfoSubmit(boothInformationId);
          } else {
            return false;
          }
        });
      },
      onSearch() {
        console.log(this.searchForm.boothCode);
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },
      cancelDialog() {
        this.dialogVisible = false;
        this.dialogForm = {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        };
        this.$refs.form.resetFields();
      },
      showDialog(row) {
        console.log(row);
        this.dialogForm = {
          boothInformationId: row.boothInformationId,
          boothCode: row.boothCode,
          areas: row.areas,
          areaId: row.areaId,
          boothUseId: row.boothUseId,
          placeId: row.placeId,
          boothTypeId: row.boothTypeId,
          remark: row.remark,
        };
        this.dialogVisible = true;
      },
      async getData() {
        let res = await Http.confirmPage(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.count = res.data.total;
          console.log(111, this.tableData);
        }
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId: this.searchForm.areaId,
        });
        if (res.code == 200) {
          this.formColumns[2].options = res.data;
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status: null,
        });
        if (res.code == 200) {
          this.formColumns[1].options = res.data;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.total {
  font-size: 40px;
  color: red;
  font-weight: 600;
}
</style>
